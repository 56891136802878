<template>
  <section class="text-gray-600 body-font relative">
    <div class="container px-5 sm:py-24 mx-auto">
      <div class="flex flex-col text-center w-full mb-12">
        <h1
          class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900"
        >
          Register
        </h1>
        <p class="lg:w-2/3 mx-auto leading-relaxed text-base">
          To register for online voting, please enter your registry number and
          date of birth below.
        </p>
        <alert
          v-if="hasSubmitError"
          type="error"
          title="Alert"
          text="The information you provided could not be confirmed. Please
              carefully try entering your information again. If you are unable
              to proceed, please contact the Electoral at:
              ronlauferelections@gmail.com or mobile: 604-715-4777."
          class="mt-4"
        >
        </alert>
        <div v-if="formState === 'submitting'" class="w-full mx-auto">
          <Spinner />
        </div>
      </div>
      <form
        v-if="formState === 'toSubmit'"
        @submit.prevent="submit"
        class="md:w-2/3 mx-auto"
      >
        <div class="flex flex-wrap -m-2">
          <div class="p-2 w-full md:w-1/2">
            <div class="relative w-full">
              <label for="code" class="leading-7 text-gray-600"
                >Registry Number
              </label>
              <input
                v-model="code"
                required
                type="text"
                id="code"
                name="code"
                maxlength="10"
                placeholder="10-digit status card"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                class="
                  w-full
                  bg-gray-100 bg-opacity-50
                  rounded
                  border border-gray-300
                  focus:border-blue-500
                  focus:bg-white
                  focus:ring-2
                  focus:ring-blue-200
                  text-base
                  outline-none
                  text-gray-700
                  py-1
                  px-3
                  leading-8
                  transition-colors
                  duration-200
                  ease-in-out
                  placeholder-gray-500
                "
              />
            </div>
          </div>
          <div class="p-2 w-full md:w-1/2">
            <label for="year" class="leading-7 text-gray-600">
              Date of Birth
            </label>
            <div class="relative w-full">
              <input
                v-model="year"
                required
                type="text"
                id="year"
                name="year"
                placeholder="YYYY"
                maxlength="4"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                class="
                  w-full
                  md:w-1/4 md:mr-2
                  mb-2
                  md:mb-0
                  bg-gray-100 bg-opacity-50
                  rounded
                  border border-gray-300
                  focus:border-blue-500
                  focus:bg-white
                  focus:ring-2
                  focus:ring-blue-200
                  text-base
                  outline-none
                  text-gray-700
                  py-1
                  px-3
                  leading-8
                  transition-colors
                  duration-200
                  ease-in-out
                  placeholder-gray-500
                  inline-flex
                "
              />
              <select
                v-model="month"
                id="month"
                name="month"
                :required="true"
                class="
                  w-full
                  md:w-2/5
                  lg:1/2
                  md:mr-2
                  mb-2
                  md:mb-0
                  bg-gray-100 bg-opacity-50
                  rounded
                  border border-gray-300
                  focus:border-blue-500
                  focus:bg-white
                  focus:ring-2
                  focus:ring-blue-200
                  text-base
                  outline-none
                  text-gray-700
                  py-2
                  px-3
                  leading-8
                  transition-colors
                  duration-200
                  ease-in-out
                  inline-flex
                "
              >
                <option
                  v-for="(m, index) in monthOptions"
                  :key="`${m.name}-${index}`"
                  :value="m.value"
                >
                  {{ m.name }}
                </option>
              </select>
              <input
                v-model="day"
                required
                type="text"
                id="day"
                name="day"
                maxlength="2"
                placeholder="DD"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                class="
                  w-full
                  md:w-1/5
                  bg-gray-100 bg-opacity-50
                  rounded
                  border border-gray-300
                  focus:border-blue-500
                  focus:bg-white
                  focus:ring-2
                  focus:ring-blue-200
                  text-base
                  outline-none
                  text-gray-700
                  py-1
                  px-3
                  leading-8
                  transition-colors
                  duration-200
                  ease-in-out
                  placeholder-gray-500
                  inline-flex
                "
              />
            </div>
          </div>
          <div
            v-if="formErrors.length"
            class="text-sm text-red-600 mt-4 w-full text-center"
          >
            <b>Please correct the following error(s):</b>
            <p v-for="(error, index) in formErrors" :key="`error-${index}`">
              {{ error }}
            </p>
          </div>
          <div class="p-2 w-full mt-8">
            <button
              type="submit"
              class="
                mx-2
                my-2
                w-32
                md:w-36
                text-white
                bg-green-500
                border border-green-500
                py-2
                px-8
                focus:outline-none
                hover:bg-green-600
                rounded
                text-base
                md:text-lg
                inline-block
              "
            >
              Submit
            </button>
            <router-link :to="`/${$route.params.nationSlug}`">
              <button
                class="
                  mx-2
                  my-2
                  w-32
                  md:w-36
                  text-primary
                  bg-gray-100
                  border border-gray-300
                  py-2
                  px-8
                  focus:outline-none
                  hover:bg-gray-200
                  rounded
                  text-base
                  md:text-lg
                  inline-block
                "
              >
                Back
              </button>
            </router-link>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { getRegistrant } from "@/services/ApiService";
import Alert from "@/components/Alert";
import Spinner from "@/components/Spinner";
import router from "../router";

export default {
  name: "register-form",
  props: ["nationSlug"],
  components: {
    Alert,
    Spinner
  },
  data() {
    return {
      formState: "toSubmit",
      code: "",
      formErrors: [],
      hasSubmitError: false,
      year: "",
      month: "01",
      day: "",
      monthOptions: [
        { name: "January", value: "01" },
        { name: "February", value: "02" },
        { name: "March", value: "03" },
        { name: "April", value: "04" },
        { name: "May", value: "05" },
        { name: "June", value: "06" },
        { name: "July", value: "07" },
        { name: "August", value: "08" },
        { name: "September", value: "09" },
        { name: "October", value: "10" },
        { name: "November", value: "11" },
        { name: "December", value: "12" }
      ]
    };
  },
  methods: {
    submit: function() {
      this.hasSubmitError = false;
      this.formErrors = [];
      if (this.checkForm()) {
        this.formState = "submitting";
        const paddedDay = this.day.length > 1 ? this.day : `0${this.day}`;
        const birthdate = `${this.year}-${this.month}-${paddedDay}`;
        getRegistrant(this.nationSlug, this.code, birthdate)
          .then(result => {
            router.push({
              name: "Update Registry",
              params: {
                code: result.data.code,
                birthdate: result.data.birthdate,
                nationSlug: this.nationSlug
              }
            });
          })
          .catch(() => {
            this.formState = "toSubmit";
            this.hasSubmitError = true;
            window.scroll(0, 0);
          });
      }
    },
    checkForm: function() {
      this.formErrors = [];

      if (this.code.length != 10) {
        this.formErrors.push("Registry number must be 10 digits.");
      }
      if (this.year.length != 4) {
        this.formErrors.push("Enter a valid birth year.");
      }
      if (parseInt(this.day) > 31) {
        this.formErrors.push("Enter a valid birth day.");
      }

      if (this.formErrors.length) return false;
      return true;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
