<template>
  <section class="text-gray-600 body-font relative">
    <div class="container px-5 sm:py-24 mx-auto">
      <div class="flex flex-col text-center w-full mb-3 sm:mb-12">
        <h1
          class="
            sm:text-3xl
            text-2xl
            font-medium
            title-font
            mb-0
            sm:mb-4
            text-gray-900
          "
        >
          Contact Information
        </h1>
        <p
          v-if="registrant && formState === 'toSubmit' && !hasSubmitError"
          class="lg:w-2/3 mx-auto leading-relaxed text-base"
        >
          Thank you, {{ registrant.given_name }}. To register for online voting,
          we need your email address. Please provide your email address and
          update any other contact information below. After completing this next
          step, you will receive an email requesting you verify your email
          address.
        </p>
        <alert
          id="submitErrorAlert"
          v-if="hasSubmitError"
          type="error"
          title="Alert"
          text="The information you provided could not be confirmed. Please
              carefully try entering your information again. If you are unable
              to proceed, please contact the Electoral at:
              ronlauferelections@gmail.com or mobile: 604-715-4777."
          class="mt-4"
        >
        </alert>
      </div>
      <div v-if="!registrant || formState === 'submitting'" class="w-full">
        <Spinner />
      </div>
      <div v-if="formState === 'submitted'" class="p-2 w-full">
        <alert
          type="warning"
          title="ALMOST DONE"
          text="Check your email inbox to verify and complete your registration."
        >
        </alert>
        <div class="p-2 w-full items-center mt-12">
          <router-link :to="`/${$route.params.nationSlug}`">
            <button
              class="
                mx-2
                w-32
                md:w-36
                text-primary
                bg-gray-100
                border border-gray-300
                py-2
                px-8
                focus:outline-none
                hover:bg-gray-200
                rounded
                text-base
                md:text-lg
                inline-block
              "
            >
              Home
            </button>
          </router-link>
        </div>
      </div>
      <form
        v-if="registrant && formState === 'toSubmit'"
        @submit.prevent="submit"
        class="text-left md:w-3/5 mx-auto"
      >
        <h2
          class="
            tracking-widest
            text-left
            pt-10
            md:text-center
            text-xl
            md:text-2xl
            title-font
            font-medium
            text-black-400
            mb-5
            md:pt-5
          "
        >
          Hello,
          <span class="font-semibold"
            >{{ registrant.given_name }} {{ registrant.middle_name }}
            {{ registrant.last_name }}</span
          >
        </h2>

        <div class="flex flex-wrap -m-2 mb-3">
          <input v-model="registrant.id" type="hidden" />
          <input v-model="registrant.code" type="hidden" />
          <input v-model="registrant.birthdate" type="hidden" />
          <input v-model="registrant.sex" type="hidden" />
        </div>
        <h2
          class="
            tracking-widest
            text-xs
            md:text-sm
            title-font
            font-medium
            text-gray-400
            mb-1
            mt-8
          "
        >
          CONFIRM YOUR EMAIL ADDRESS
        </h2>
        <hr class="mb-2 md:mb-4" />
        <div class="flex flex-wrap -m-2 mb-3">
          <div class="p-2 w-full md:w-1/2">
            <div class="relative">
              <label for="email" class="leading-7 text-md text-gray-600">
                Email Address<span class="text-red-400">* </span>
              </label>
              <p
                v-if="registrant.is_verified"
                class="
                  inline-block
                  text-xs
                  bg-green-100
                  text-green-600
                  font-bold
                  rounded-full
                  px-2.5
                  py-0.5
                  my-1
                  uppercase
                "
              >
                <span
                  class="
                    text-green-500
                    w-4
                    h-4
                    inline-flex
                    items-center
                    justify-center
                  "
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Verified
              </p>
              <input
                v-model="registrant.email"
                required
                type="email"
                id="email"
                name="email"
                class="
                  w-full
                  bg-gray-100 bg-opacity-50
                  rounded
                  border border-gray-300
                  focus:border-blue-500
                  focus:bg-white
                  focus:ring-2
                  focus:ring-blue-200
                  text-base
                  outline-none
                  text-gray-700
                  py-1
                  px-3
                  leading-8
                  transition-colors
                  duration-200
                  ease-in-out
                "
              />
            </div>
          </div>
          <div class="p-2 w-full md:w-1/2 text-left">
            <div class="relative">
              <label for="confirmEmail" class="leading-7 text-md text-gray-600">
                Confirm Email Address<span class="text-red-400">*</span>
              </label>
              <input
                v-model="confirmEmail"
                required
                type="email"
                id="confirmEmail"
                name="confirmEmail"
                placeholder="you@example.com"
                class="
                  w-full
                  bg-gray-100 bg-opacity-50
                  rounded
                  border border-gray-300
                  focus:border-blue-500
                  focus:bg-white
                  focus:ring-2
                  focus:ring-blue-200
                  text-base
                  outline-none
                  text-gray-700
                  py-1
                  px-3
                  leading-8
                  transition-colors
                  duration-200
                  ease-in-out
                "
              />
            </div>
          </div>
          <p class="text-xs w-full text-right mr-2">
            <span class="text-red-400">*</span> Required fields
          </p>
        </div>
        <h2
          class="
            tracking-widest
            text-xs
            md:text-sm
            title-font
            font-medium
            text-gray-400
            mb-1
            mt-8
          "
        >
          UPDATE YOUR INFO
        </h2>
        <hr class="mb-2 md:mb-4" />
        <div class="flex flex-wrap -m-2 mb-2">
          <div class="p-2 w-full text-left">
            <div class="relative">
              <label for="address" class="leading-7 text-md text-gray-600">
                Street Address
              </label>
              <input
                v-model="registrant.address"
                type="text"
                id="address"
                name="address"
                class="
                  w-full
                  bg-gray-100 bg-opacity-50
                  rounded
                  border border-gray-300
                  focus:border-blue-500
                  focus:bg-white
                  focus:ring-2
                  focus:ring-blue-200
                  text-base
                  outline-none
                  text-gray-700
                  py-1
                  px-3
                  leading-8
                  transition-colors
                  duration-200
                  ease-in-out
                "
              />
            </div>
          </div>
        </div>
        <div class="flex flex-wrap -m-2 mb-3">
          <div class="p-2 w-full md:w-1/2 text-left">
            <div class="relative">
              <label for="city" class="leading-7 text-md text-gray-600">
                City
              </label>
              <input
                v-model="registrant.city"
                type="text"
                id="city"
                name="city"
                class="
                  w-full
                  bg-gray-100 bg-opacity-50
                  rounded
                  border border-gray-300
                  focus:border-blue-500
                  focus:bg-white
                  focus:ring-2
                  focus:ring-blue-200
                  text-base
                  outline-none
                  text-gray-700
                  py-1
                  px-3
                  leading-8
                  transition-colors
                  duration-200
                  ease-in-out
                "
              />
            </div>
          </div>
          <div class="p-2 w-full md:w-1/2 text-left">
            <div class="relative">
              <label for="province" class="leading-7 text-md text-gray-600">
                Province
              </label>
              <input
                v-model="registrant.province"
                type="text"
                id="province"
                name="province"
                class="
                  w-full
                  bg-gray-100 bg-opacity-50
                  rounded
                  border border-gray-300
                  focus:border-blue-500
                  focus:bg-white
                  focus:ring-2
                  focus:ring-blue-200
                  text-base
                  outline-none
                  text-gray-700
                  py-1
                  px-3
                  leading-8
                  transition-colors
                  duration-200
                  ease-in-out
                "
              />
            </div>
          </div>
        </div>
        <div class="flex flex-wrap -m-2 mb-3">
          <div class="p-2 w-full md:w-2/3 text-left">
            <div class="relative">
              <label for="country" class="leading-7 text-md text-gray-600">
                Country (Required if International)
              </label>
              <select
                v-model="registrant.country"
                id="country"
                name="country"
                class="
                  w-full
                  bg-gray-100 bg-opacity-50
                  rounded
                  border border-gray-300
                  focus:border-blue-500
                  focus:bg-white
                  focus:ring-2
                  focus:ring-blue-200
                  text-base
                  outline-none
                  text-gray-700
                  pt-2
                  pb-1.5
                  px-3
                  leading-8
                  transition-colors
                  duration-200
                  ease-in-out
                "
              >
                <option value=""></option>
                <option
                  v-for="(country, index) in countries"
                  :key="`country-${index}`"
                >
                  {{ country }}
                </option>
              </select>
            </div>
          </div>
          <div class="p-2 w-full md:w-1/3 text-left">
            <div class="relative">
              <label for="zipCode" class="leading-7 text-md text-gray-600">
                Postal/Zip Code
              </label>
              <input
                v-model="registrant.zip_code"
                type="text"
                id="zipCode"
                name="zipCode"
                class="
                  w-full
                  bg-gray-100 bg-opacity-50
                  rounded
                  border border-gray-300
                  focus:border-blue-500
                  focus:bg-white
                  focus:ring-2
                  focus:ring-blue-200
                  text-base
                  outline-none
                  text-gray-700
                  py-1
                  px-3
                  leading-8
                  transition-colors
                  duration-200
                  ease-in-out
                "
              />
            </div>
          </div>
        </div>
        <div class="flex flex-wrap -m-2 mb-3">
          <div class="p-2 w-full text-left">
            <div class="relative">
              <label for="phoneNumber" class="leading-7 text-md text-gray-600">
                Phone No.
              </label>
              <input
                v-model="registrant.phone"
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                class="
                  w-full
                  bg-gray-100 bg-opacity-50
                  rounded
                  border border-gray-300
                  focus:border-blue-500
                  focus:bg-white
                  focus:ring-2
                  focus:ring-blue-200
                  text-base
                  outline-none
                  text-gray-700
                  py-1
                  px-3
                  leading-8
                  transition-colors
                  duration-200
                  ease-in-out
                "
              />
            </div>
          </div>
        </div>
        <div class="flex flex-wrap -m-2 mb-3">
          <div
            v-if="formErrors.length"
            class="text-sm text-red-600 mt-4 w-full text-center"
          >
            <b>Please correct the following error(s):</b>
            <p v-for="(error, index) in formErrors" :key="`error-${index}`">
              {{ error }}
            </p>
          </div>
        </div>
        <div class="flex flex-col text-center w-full mt-8 md:mt-12">
          <div class="p-2 w-full items-center">
            <button
              type="submit"
              class="
                mx-2
                my-2
                w-32
                md:w-36
                text-white
                bg-green-500
                border border-green-500
                py-2
                px-8
                focus:outline-none
                hover:bg-green-600
                rounded
                text-base
                md:text-lg
                inline-block
              "
            >
              Verify
            </button>
            <router-link :to="`/${$route.params.nationSlug}/register`">
              <button
                class="
                  mx-2
                  my-2
                  w-32
                  md:w-36
                  text-primary
                  bg-gray-100
                  border border-gray-300
                  py-2
                  px-8
                  focus:outline-none
                  hover:bg-gray-200
                  rounded
                  text-base
                  md:text-lg
                  inline-block
                "
              >
                Cancel
              </button>
            </router-link>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { updateRegistrant } from "@/services/ApiService";
import Alert from "@/components/Alert";
import Spinner from "@/components/Spinner";

const { getNames } = require("country-list");

export default {
  name: "update-registry-form",
  props: ["registrant"],
  components: {
    Alert,
    Spinner
  },
  data() {
    return {
      confirmEmail: "",
      formState: "toSubmit",
      hasSubmitError: false,
      formErrors: [],
      countries: getNames().sort()
    };
  },
  methods: {
    submit() {
      if (this.checkForm()) {
        this.formState = "submitting";
        this.hasSubmitError = false;
        this.errors = [];
        updateRegistrant(this.registrant)
          .then(() => {
            this.formState = "submitted";
          })
          .catch(() => {
            this.formState = "toSubmit";
            this.hasSubmitError = true;
            window.scrollTo(0, 0);
          });
      }
    },
    checkForm: function() {
      this.formErrors = [];
      if (this.registrant.email != this.confirmEmail) {
        this.formErrors.push("Email addresses do not match.");
      }
      if (this.formErrors.length) return false;
      return true;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
