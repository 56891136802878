<template>
  <section class="text-gray-600 body-font h-full">
    <div class="flex justify-center items-center h-full">
      <div class="mr-0 sm:mr-20">
        <img
          v-if="nation.logo"
          class="hidden sm:block max-h-72 max-w-xs"
          alt="hero"
          :src="nation.logo"
        />
      </div>
      <div class="flex-grow">
        <h1
          class="
            title-font
            text-3xl
            sm:text-4xl
            mb-4
            font-lg
            text-gray-900 text-center
          "
        >
          Register for Electronic Voting
        </h1>
        <div class="flex justify-center mb-4 sm:mb-12">
          <router-link :to="`/${nation.slug}/register`">
            <button
              class="
                inline-flex
                text-white
                bg-green-500
                border-0
                py-2
                px-6
                focus:outline-none
                hover:bg-green-700
                rounded
                text-lg
                disabled:opacity-50
                disabled:cursor-not-allowed
              "
              :disabled="!nation.is_voting_enabled"
            >
              Register
            </button>
          </router-link>
        </div>

        <div
          class="
            flex flex-col
            lg:flex-row
            items-center
            lg:items-stretch
            justify-center
            w-full
          "
        >
          <Card
            class="my-4 lg:mx-5"
            v-for="(noticeCard, index) in noticeCards"
            :key="index"
          >
            <h2
              class="
                text-lg
                lg:text-xl
                text-gray-900
                font-medium
                title-font
                mb-4
              "
            >
              {{ noticeCard.title }}
            </h2>
            <div v-html="noticeCard.details"></div>
          </Card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Card from "../components/Card.vue";

export default {
  components: {
    Card
  },
  name: "NationHome",
  computed: {
    nation() {
      return this.$store.getters.getNationBySlug(this.$route.params.nationSlug);
    },
    noticeCards() {
      if (this.nation.notice_cards) {
        return (
          this.nation.notice_cards.slice().sort((a, b) => {
            return a.id - b.id;
          }) || []
        );
      }
      return [];
    }
  }
};
</script>
