<template>
  <div class="bg-gray-100 p-6 rounded-lg max-w-card w-full">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Card"
};
</script>
