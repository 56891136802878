<template>
  <footer class="text-gray-600 body-font">
    <div class="bg-gray-100">
      <div
        class="
          container
          mx-auto
          py-10
          px-5
          flex flex-wrap flex-col
          sm:flex-row
          justify-between
          text-gray-500
        "
      >
        <div class="text-sm text-center md:text-base flex-wrap max-w-full">
          © {{ new Date().getFullYear() }} {{ nationName }} —
          <p class="md:mr-4 inline-block">Electoral Officer: Ron Laufer</p>
          <p class="md:mr-4 inline-block">ronlauferelections@gmail.com</p>
          <p class="md:mr-4 inline-block">Mobile Phone: 604-715-4777</p>
        </div>
        <p class="text-sm md:text-base mt-2 md:mt-0">
          made with &#10084; by
          <a
            class="hover:text-green-700"
            href="https://countable.ca"
            target="_blank"
            >Countable.ca</a
          >
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
  data() {
    return {
      nationName: "Evoter.ca"
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      const nation = to.params.nation;
      this.loadCurrentNation(nation);
    }
  },
  mounted() {
    const nation = this.$route.params.nation;
    this.loadCurrentNation(nation);
  },
  methods: {
    loadCurrentNation: function(nation) {
      const currentRouteName = this.$route.name;

      if (currentRouteName.includes("Tlazten") || nation === "tlazten") {
        this.nationName = "Tl'azt'en Nation";
      } else if (
        currentRouteName.includes("Heiltsuk") ||
        nation === "heiltsuk"
      ) {
        this.nationName = "Heiltsuk Nation";
      } else if (currentRouteName.includes("Binche") || nation === "binche") {
        this.nationName = "Binche Whut'en";
      } else {
        this.nationName = "Evoter.ca";
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
