<template>
  <div id="app" class="flex flex-col">
    <navbar />
    <div class="flex-1 max-w-screen-xl w-full mx-auto px-5 py-10">
      <transition
        mode="out-in"
        enter-active-class="translate-performant duration-300 ease-in"
        leave-active-class="translate-performant duration-300 ease-in"
        enter-class="opacity-0 transform"
        leave-to-class="opacity-0 transform"
      >
        <router-view />
      </transition>
    </div>
    <div class="flex-shrink-0">
      <app-footer />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import AppFooter from "@/components/AppFooter.vue";
export default {
  components: {
    Navbar,
    AppFooter
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Voter Registry";
      }
    }
  },
  mounted() {
    this.$store.dispatch("loadNations");
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

#app {
  font-family: "Poppins", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html,
body,
#app {
  @apply w-full h-full;
}
</style>
