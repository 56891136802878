import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex, axios);

export default new Vuex.Store({
  state: {
    nations: []
  },
  mutations: {
    setNations(state, nations) {
      state.nations = nations;
    }
  },
  actions: {
    loadNations({ commit }) {
      axios
        .get("/api/registrants/nations")
        .then(response => {
          commit("setNations", response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  getters: {
    getActiveNations: state => {
      return state.nations.filter(nation => !nation.is_nation_archived);
    },
    getInactiveNations: state => {
      return state.nations.filter(nation => nation.is_nation_archived);
    },
    getNationBySlug: state => slug => {
      return state.nations.find(nation => nation.slug === slug) || {};
    }
  },
  modules: {}
});
