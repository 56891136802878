<template>
  <section class="text-gray-600 body-font h-full">
    <div class="flex justify-center items-center h-full flex-col">
      <div class="container px-5 mx-auto">
        <div class="text-center mb-10 lg:mb-20">
          <h1
            class="
              sm:text-3xl
              text-2xl
              font-medium
              title-font
              text-gray-900
              mb-4
            "
          >
            Welcome to the Evoter Online Voter Registration Page
          </h1>
          <div class="flex mt-6 justify-center">
            <div class="w-16 h-1 rounded-full bg-gray-500 inline-flex"></div>
          </div>
        </div>
      </div>
      <div class="container px-5 mx-auto">
        <div
          class="flex flex-col lg:flex-row flex-wrap m-4 items-center justify-center"
        >
          <router-link
            v-for="(nation, index) in getActiveNations"
            :key="`${nation.slug}-${index}`"
            :to="`/${nation.slug}`"
            class="p-4 lg:w-1/2 xl:w-2/5 w-full lg:max-w-full max-w-md"
          >
            <div
              class="
                flex
                border-2
                rounded-lg
                bg-gray-100
                border-gray-200 border-opacity-50
                p-12
                lg:p-8
                sm:flex-row
                flex-col
                items-center
              "
            >
              <div
                class="
                  w-24
                  h-24
                  sm:mr-8 sm:mb-0
                  mb-4
                  inline-flex
                  items-center
                  justify-center
                  rounded-full
                  bg-white
                  flex-shrink-0
                "
              >
                <img :src="nation.logo" class="h-10 w-auto" />
              </div>
              <div class="flex-grow">
                <h2 class="text-gray-900 text-2xl title-font font-medium mb-3">
                  {{ nation.name }}
                </h2>
                <a
                  v-if="nation.is_voting_enabled"
                  class="
                    inline-flex
                    items-center
                    text-white
                    bg-green-500
                    border-0
                    py-2
                    px-6
                    focus:outline-none
                    hover:bg-green-700
                    rounded
                    text-lg
                    disabled:opacity-50
                    disabled:cursor-not-allowed
                  "
                  >Register
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </a>
                <a
                  v-else
                  class="
                    inline-flex
                    items-center
                    text-gray-500
                    bg-gray-200
                    border-0
                    py-2
                    px-6
                    focus:outline-none
                    hover:bg-gray-300
                    hover:text-green-700
                    rounded
                    text-lg
                    disabled:opacity-50
                    disabled:cursor-not-allowed
                  "
                  >More Info
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Home",
  computed: {
    ...mapGetters(["getActiveNations"])
  }
};
</script>
