import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import NationHome from "../views/NationHome.vue";
import Archived from "../views/Archived.vue";
import Register from "../views/Register.vue";
import UpdateRegistry from "../views/UpdateRegistry.vue";
import Verify from "../views/Verify.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Voter Registry | Home"
    }
  },
  {
    path: "/partner-nations",
    name: "Partner Nations",
    component: Archived,
    meta: {
      title: "Voter Registry | Partners"
    }
  },
  {
    path: "/:nationSlug",
    name: "Nation Home",
    component: NationHome,
    meta: {
      title: "Voter Registry | Nation Home"
    }
  },
  {
    path: "/:nationSlug/register",
    name: "Register",
    component: Register,
    meta: {
      title: "Voter Registry | Register"
    }
  },
  {
    path: "/:nationSlug/registrant/:code/:birthdate",
    name: "Update Registry",
    component: UpdateRegistry,
    props: true,
    meta: {
      title: "Voter Registry | Update User Details"
    }
  },
  {
    path: "/:nationSlug/verify",
    name: "Verify",
    component: Verify,
    meta: {
      title: "Voter Registry | Verification"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
