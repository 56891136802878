<template>
  <div class="p-2 w-full">
    <div
      class="p-3 bg-red-100 items-center text-red-600 leading-normal rounded-xl block md:inline-flex shadow-sm transition-colors duration-200 ease-in-out"
      :class="{
        'bg-red-100': type == 'danger',
        'text-red-600': type == 'danger',
        'bg-green-100': type == 'success',
        'text-green-700': type == 'success',
        'bg-yellow-100': type == 'warning',
        'text-yellow-600': type == 'warning',
      }"
      role="alert"
    >
      <p
        class="block md:flex rounded-full bg-red-500 uppercase text-white px-4 py-1 text-sm md:text-base lg:mr-3 font-bold shadow-sm text-center mb-3 md:mb-0"
        :class="{
          'bg-red-500': type == 'danger',
          'bg-green-500': type == 'success',
          'bg-yellow-500': type == 'warning'
        }"
      >
        {{ title }}
      </p>
      <p
        class="mr-2 text-center md:text-left block md:flex-auto text-sm md:text-base mb-1 md:mb-0"
      >
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "alert",
  props: ["title", "text", "type"]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
