<template>
  <header class="text-gray-600 body-font shadow">
    <div
      class="
        container
        mx-auto
        flex flex-wrap
        p-5
        flex-col
        md:flex-row
        items-center
        w-full
      "
    >
      <div class="mb-4 md:mb-0">
        <img
          v-if="nation.logo"
          class="inline-block sm:hidden max-h-10 mr-2"
          alt="hero"
          :src="nation.logo"
        />

        <router-link
          :to="Object.keys(nation).length ? `/${nation.slug}` : '/'"
          class="inline-block"
        >
          <span
            class="
              text-xl
              cursor-pointer
              flex
              title-font
              font-medium
              items-center
              text-gray-900
              uppercase
            "
            >{{
              Object.keys(nation).length ? `${nation.name} Nation` : "Evoter.ca"
            }}</span
          >
        </router-link>
      </div>

      <nav
        class="
          md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400
          flex flex-wrap flex-grow
          items-center
          text-base
          justify-center
        "
      >
        <router-link
          key="Home"
          to="/"
          class="hover:text-green-700 mr-5"
          >Home</router-link
        >
        <router-link
          v-for="(link, index) in links"
          :key="`${link.text}-${index}`"
          :to="link.path"
          class="hover:text-green-700 mr-5"
          >{{ link.text }}</router-link
        >
        <div class="flex flex-1">
          <router-link
            v-if="getInactiveNations.length > 0"
            key="partner-nations"
            to="/partner-nations"
            class="hover:text-green-700 ml-auto"
            >Other Nations</router-link
          >
        </div>
      </nav>
      <a
        v-if="nation.election_path"
        :href="nation.election_path"
        target="_blank"
        :class="{ invisible: !nation.election_path }"
      >
        <button class="election-button">
          Detailed Election Info
          <svg
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            class="w-4 h-4 ml-1"
            viewBox="0 0 24 24"
          >
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </button>
      </a>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import { isEmpty } from 'lodash';

export default {
  name: "navbar",
  computed: {
    ...mapGetters(["getActiveNations", "getInactiveNations"]),
    nation() {
      return this.$store.getters.getNationBySlug(this.$route.params.nationSlug);
    },
    links() {
      const nations = this.getActiveNations;
      const linksArray = [];
      if (isEmpty(this.nation)) {
        nations.forEach(n => {
          if (n.name !== this.nation.name) {
            linksArray.push({
              text: n.name,
              path: `/${n.slug}`
            });
          }
        });
      } else if (this.nation.is_voting_enabled) {
        linksArray.push({
          text: 'Register',
          path: `/${this.nation.slug}/register`
        });
      }
      return linksArray;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.election-button {
  @apply inline-flex items-center text-black border-0 py-2 px-4 focus:outline-none;
  @apply rounded-md mt-4 md:mt-0;
}
</style>
