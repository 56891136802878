<template>
  <update-registry-form :registrant="registrant"></update-registry-form>
</template>

<script>
// @ is an alias to /src
import { getRegistrant } from "@/services/ApiService";
import UpdateRegistryForm from "@/components/UpdateRegistryForm.vue";
export default {
  data() {
    return {
      registrant: null
    };
  },
  components: {
    UpdateRegistryForm
  },
  mounted() {
    const code = this.$route.params.code;
    const birthdate = this.$route.params.birthdate;
    const nationSlug = this.$route.params.nationSlug;
    getRegistrant(nationSlug, code, birthdate)
      .then(result => {
        this.registrant = result.data;
      })
      .catch(e => {
        console.log(e);
      });
  }
};
</script>
