var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2 w-full"},[_c('div',{staticClass:"p-3 bg-red-100 items-center text-red-600 leading-normal rounded-xl block md:inline-flex shadow-sm transition-colors duration-200 ease-in-out",class:{
      'bg-red-100': _vm.type == 'danger',
      'text-red-600': _vm.type == 'danger',
      'bg-green-100': _vm.type == 'success',
      'text-green-700': _vm.type == 'success',
      'bg-yellow-100': _vm.type == 'warning',
      'text-yellow-600': _vm.type == 'warning',
    },attrs:{"role":"alert"}},[_c('p',{staticClass:"block md:flex rounded-full bg-red-500 uppercase text-white px-4 py-1 text-sm md:text-base lg:mr-3 font-bold shadow-sm text-center mb-3 md:mb-0",class:{
        'bg-red-500': _vm.type == 'danger',
        'bg-green-500': _vm.type == 'success',
        'bg-yellow-500': _vm.type == 'warning'
      }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',{staticClass:"mr-2 text-center md:text-left block md:flex-auto text-sm md:text-base mb-1 md:mb-0"},[_vm._v(" "+_vm._s(_vm.text)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }