<template>
  <register-form
    v-if="nation.is_voting_enabled"
    :nationSlug="$route.params.nationSlug"
  ></register-form>
  <div v-else class="flex justify-center items-center h-full">
    <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
      Voting is Closed for {{ nation.name }}
    </h1>
  </div>
</template>

<script>
import RegisterForm from "@/components/RegisterForm.vue";
export default {
  components: {
    RegisterForm,
  },
  computed: {
    nation() {
      return this.$store.getters.getNationBySlug(this.$route.params.nationSlug);
    },
  },
};
</script>
