<template>
  <section class="text-gray-600 body-font h-full">
    <div class="flex justify-center items-center h-full flex-col">
      <div class="container px-5 mx-auto">
        <div class="text-center mb-10 lg:mb-20">
          <h1
            class="
              sm:text-3xl
              text-2xl
              font-medium
              title-font
              text-gray-900
              mb-4
            "
          >
            Other Nations Partnered with Evoter
          </h1>
          <div class="flex mt-6 justify-center">
            <div class="w-16 h-1 rounded-full bg-gray-500 inline-flex"></div>
          </div>
        </div>
      </div>
      <div class="container px-5 mx-auto">
        <div
          class="flex flex-col lg:flex-row flex-wrap m-4 items-center justify-center"
        >
          <div
            v-for="(nation, index) in getInactiveNations"
            :key="`${nation.slug}-${index}`"
            class="p-4 lg:w-1/3 md:w-full"
          >
            <div
              class="
                flex
                border-2
                rounded-lg
                bg-gray-100
                border-gray-200 border-opacity-50
                p-12
                lg:p-8
                sm:flex-row
                flex-col
                items-center
              "
            >
              <div
                class="
                  w-24
                  h-24
                  sm:mr-8 sm:mb-0
                  mb-4
                  inline-flex
                  items-center
                  justify-center
                  rounded-full
                  bg-white
                  flex-shrink-0
                "
              >
                <img :src="nation.logo" class="h-10 w-auto" />
              </div>
              <div class="flex-grow">
                <h2 class="text-gray-900 text-2xl title-font font-medium">
                  {{ nation.name }}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Archived",
  computed: {
    ...mapGetters(["getInactiveNations"])
  }
};
</script>
