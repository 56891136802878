<template>
  <section class="text-gray-600 body-font relative">
    <div class="container px-5 sm:py-24 mx-auto">
      <div class="flex flex-col text-center w-full mb-4">
        <h1
          class="sm:text-3xl text-2xl font-medium title-font mb-8 text-gray-900"
        >
          Verification
        </h1>
        <div v-if="!result && !hasError" class="w-full mx-auto">
          <Spinner />
        </div>
        <div
          v-if="result && !hasError"
          class="lg:w-2/3 mx-auto leading-relaxed text-base md:text-lg"
        >
          <p class="mb-4">
            Thanks again, {{ result.given_name }}. You are now registered to
            vote online!
          </p>
          <p class="mb-4">
            An email providing you access to the online vote will be sent
            {{ accessPeriodString }}. If you don’t receive one, please check
            your junk mail or contact the electoral officer.
          </p>
          <p>
            Remember, electronic voting ends at {{ votingPeriodEndString }}, but
            it doesn’t hurt to vote early!
          </p>
        </div>
        <div
          v-if="hasError"
          class="lg:w-2/3 mx-auto leading-relaxed text-base md:text-lg"
        >
          <p>
            There has been an error verifying your registration. Either you have
            already been verified or some other error has occured. Please
            contact the electoral officer.
          </p>
        </div>
      </div>
    </div>
    <div v-if="result || hasError" class="p-2 w-full items-center mt-8">
      <router-link to="/">
        <button
          class="
            mx-2
            w-32
            md:w-36
            text-primary
            bg-gray-100
            border border-gray-300
            py-2
            px-8
            focus:outline-none
            hover:bg-gray-200
            rounded
            text-base
            md:text-lg
            inline-block
          "
        >
          Home
        </button>
      </router-link>
    </div>
  </section>
</template>

<script>
import { verifyRegistrant } from "@/services/ApiService";
import Spinner from "@/components/Spinner";

export default {
  components: {
    Spinner
  },
  data() {
    return {
      result: null,
      hasError: false
    };
  },
  computed: {
    nation() {
      return this.$store.getters.getNationBySlug(this.$route.params.nationSlug);
    },
    accessPeriodString() {
      return this.nation.voting_email_send_text;
    },
    votingPeriodEndString() {
      return this.nation.voting_end_datetime;
    }
  },
  mounted() {
    const body = this.$route.query;
    verifyRegistrant(body)
      .then(result => {
        this.result = result.data;
      })
      .catch(e => {
        console.log(e);
        this.hasError = true;
      });
  }
};
</script>
