import axios from "axios";

export function getRegistrant(nationSlug, id, birthdate) {
  return axios.get(`/api/registrants/${id}/${birthdate}/${nationSlug}`);
}

export function updateRegistrant(body) {
  const {
    id,
    code,
    birthdate,
    nation,
    given_name,
    middle_name,
    last_name,
    email,
    phone,
    sex,
    address,
    city,
    province,
    zip_code,
    country,
    token
  } = body;
  return axios.put(`/api/registrants/${id}/?token=${token}`, {
    code: code,
    birthdate: birthdate,
    given_name: given_name,
    middle_name: middle_name,
    last_name: last_name,
    email: email,
    phone: phone,
    sex: sex,
    address: address,
    city: city,
    province: province,
    zip_code: zip_code,
    country: country,
    nation: nation
  });
}

export function verifyRegistrant(body) {
  const { id, email, token } = body;
  return axios.get(`/api/registrants/verify/${id}/${email}/${token}`);
}
